<template>
  <div id="page-ticket-details">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" icon @click="$router.go(-1)">
                    <v-icon>mdi-keyboard-backspace</v-icon>
                  </v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.different.back') }}</span>
              </v-tooltip>
              <span class="headline">{{
                $vuetify.lang.t('$vuetify.ticket.view')
              }}</span>
              <v-spacer></v-spacer>
              <v-tooltip v-if="compShowEditBtn" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    rounded
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                    class="mr-2"
                    @click="editDialog = true"
                  >
                    <v-icon left small>mdi-pencil</v-icon>
                    {{ $vuetify.lang.t('$vuetify.buttons.edit') }}
                  </v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.buttons.edit') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    rounded
                    v-on="on"
                    v-bind="attrs"
                    @click="historyDialog = true"
                  >
                    <v-icon left small>mdi-history</v-icon>
                    {{ $vuetify.lang.t('$vuetify.buttons.history') }}
                  </v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.ticket.history') }}</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-card>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td align="left"><strong>ID</strong></td>
                            <td align="right">{{ ticket.view_id }}</td>
                          </tr>
                          <tr>
                            <td align="left">
                              <strong>{{
                                $vuetify.lang.t('$vuetify.different.project')
                              }}</strong>
                            </td>
                            <td align="right">
                              <strong>{{ ticket.project.name }}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td align="left">
                              <strong>{{
                                $vuetify.lang.t('$vuetify.order.number')
                              }}</strong>
                            </td>
                            <td align="right">
                              <strong>{{ ticket.order_number }}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td align="left">
                              <strong>{{
                                $vuetify.lang.t('$vuetify.category.category')
                              }}</strong>
                            </td>
                            <td align="right">{{ compCategoryTitle }}</td>
                          </tr>
                          <tr>
                            <td align="left">
                              <strong>{{
                                $vuetify.lang.t('$vuetify.dates.createdAt')
                              }}</strong>
                            </td>
                            <td align="right">{{ ticket.created_at }}</td>
                          </tr>
                          <tr>
                            <td align="left">
                              <strong>{{
                                $vuetify.lang.t('$vuetify.user.reporter')
                              }}</strong>
                            </td>
                            <td align="right">
                              <strong>{{ compReporterTitle }}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td align="left">
                              <strong>{{
                                $vuetify.lang.t('$vuetify.user.assigned')
                              }}</strong>
                            </td>
                            <td align="right">
                                <v-chip v-for="(i) in ticket.assigned" :key="'assigned_view'+ i.id" small outlined>
                                  {{ i.name }} {{ i.surname }}
                                </v-chip>
                            </td>
                          </tr>
                          <tr>
                            <td align="left">
                              <strong>{{
                                $vuetify.lang.t('$vuetify.status.status')
                              }}</strong>
                            </td>
                            <td align="right">{{ compStatusTitle }}</td>
                          </tr>
                          <tr>
                            <td align="left">
                              <strong>{{
                                $vuetify.lang.t('$vuetify.different.remind')
                              }}</strong>
                            </td>
                            <td align="right">
                              <v-chip
                                v-if="ticket.reminder_alert"
                                small
                                dark
                                color="red"
                              >
                                {{ ticket.reminder_date }}
                              </v-chip>
                              <span v-else>
                                {{ ticket.reminder_date }}
                              </span>
                              <v-btn
                                v-if="ticket.reminder_date"
                                icon
                                small
                                @click="remind"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                  <v-card>
                    <v-card-title
                      ><span class="subtitle-2">{{
                        $vuetify.lang.t('$vuetify.different.summary')
                      }}</span>
                    </v-card-title>
                    <v-card-text>{{ ticket.summary }}</v-card-text>
                  </v-card>
                  <v-card class="mt-3">
                    <v-card-title
                      ><span class="subtitle-2">{{
                        $vuetify.lang.t('$vuetify.different.description')
                      }}</span></v-card-title
                    >
                    <v-card-text>
                      <v-textarea
                        class="disabled-textarea"
                        v-model="ticket.description"
                        readonly
                      ></v-textarea>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="order" class="mt-2">
                <v-col cols="12" sm="6" md="4">
                  <v-card>
                    <v-card-title
                      ><span class="subtitle-2">{{
                        $vuetify.lang.t('$vuetify.order.info')
                      }}</span>
                    </v-card-title>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td align="left">
                              {{ $vuetify.lang.t('$vuetify.different.shop') }}
                            </td>
                            <td align="right">
                              <span>{{ order.shop.name }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td align="left">
                              {{ $vuetify.lang.t('$vuetify.user.customer') }}
                            </td>
                            <td align="right">
                              <span
                                >{{ order.customer.salutation }}
                                {{ order.customer.firstname }}
                                {{ order.customer.lastname }}</span
                              ><br />
                              <span>{{ order.customer.number }}</span
                              ><br />
                              <span>{{ order.customer.email }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td align="left">
                              {{ $vuetify.lang.t('$vuetify.order.billing') }}
                            </td>
                            <td align="right">
                              <span
                                >{{ order.billing.salutation }}
                                {{ order.billing.firstName }}
                                {{ order.billing.lastName }}</span
                              ><br />
                              <span>{{ order.billing.street }}</span
                              ><br />
                              <span
                                v-if="order.billing.additionalAddressLine1"
                                >{{
                                  order.billing.additionalAddressLine1
                                }}</span
                              ><br
                                v-if="order.billing.additionalAddressLine1"
                              />
                              <span
                                v-if="order.billing.additionalAddressLine2"
                                >{{
                                  order.billing.additionalAddressLine2
                                }}</span
                              ><br
                                v-if="order.billing.additionalAddressLine2"
                              />
                              <span
                                >{{ order.billing.zipCode }}
                                {{ order.billing.city }}</span
                              ><br />
                              <span>{{ order.billing.country.name }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td align="left">
                              {{ $vuetify.lang.t('$vuetify.order.shipping') }}
                            </td>
                            <td align="right">
                              <span
                                >{{ order.shipping.salutation }}
                                {{ order.shipping.firstName }}
                                {{ order.shipping.lastName }}</span
                              ><br />
                              <span>{{ order.shipping.street }}</span
                              ><br />
                              <span
                                v-if="order.shipping.additionalAddressLine1"
                                >{{
                                  order.shipping.additionalAddressLine1
                                }}</span
                              ><br
                                v-if="order.shipping.additionalAddressLine1"
                              />
                              <span
                                v-if="order.shipping.additionalAddressLine2"
                                >{{
                                  order.shipping.additionalAddressLine2
                                }}</span
                              ><br
                                v-if="order.shipping.additionalAddressLine2"
                              />
                              <span
                                >{{ order.shipping.zipCode }}
                                {{ order.shipping.city }}</span
                              ><br />
                              <span>{{ order.shipping.country.name }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td align="left">
                              {{
                                $vuetify.lang.t('$vuetify.different.currency')
                              }}
                            </td>
                            <td align="right">
                              <span
                                ><strong>{{ order.currency }}</strong></span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td align="left">
                              {{ $vuetify.lang.t('$vuetify.order.total') }}
                            </td>
                            <td align="right">
                              <span
                                ><strong>{{
                                  order.invoiceAmount
                                }}</strong></span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td align="left">
                              {{
                                $vuetify.lang.t('$vuetify.order.paymentType')
                              }}
                            </td>
                            <td align="right">
                              <span
                                ><strong>{{ order.payment.name }}</strong></span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td align="left">
                              {{
                                $vuetify.lang.t('$vuetify.order.shippingType')
                              }}
                            </td>
                            <td align="right">
                              <span
                                ><strong>{{
                                  order.dispatch.name
                                }}</strong></span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td align="left">
                              {{ $vuetify.lang.t('$vuetify.order.status') }}
                            </td>
                            <td align="right">
                              <span
                                ><strong>{{
                                  order.orderStatus.name
                                }}</strong></span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td align="left">
                              {{
                                $vuetify.lang.t('$vuetify.order.paymentStatus')
                              }}
                            </td>
                            <td align="right">
                              <span
                                ><strong>{{
                                  order.paymentStatus.name
                                }}</strong></span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                  <v-card>
                    <v-card-title
                      >{{ $vuetify.lang.t('$vuetify.order.positions') }}
                    </v-card-title>
                    <v-data-table
                      :headers="compPositionsHeaders"
                      :items="order.details"
                      :items-per-page="10"
                      class="elevation-1"
                      dense
                    >
                      <template v-slot:item.price="{ item }">
                        {{ item.price.toFixed(2) }}
                      </template>
                      <template v-slot:item.total="{ item }">
                        {{ (item.price * item.quantity).toFixed(2) }}
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="2">
                    <v-select
                      v-model="assignTo"
                      :items="compUsersList"
                      :label="$vuetify.lang.t('$vuetify.user.assignTo')"
                      clearable
                      multiple
                      persistent-hint
                      item-value="id"
                      item-text="title"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select
                      v-model="statusTo"
                      :items="compTicketStatuses"
                      :label="$vuetify.lang.t('$vuetify.status.change')"
                      clearable
                      item-value="id"
                      item-text="title"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-menu
                      v-model="reminderToDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="250px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="remindTo"
                          :label="$vuetify.lang.t('$vuetify.different.remind')"
                          prepend-icon="event"
                          readonly
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :min="nowDate"
                        first-day-of-week="1"
                        v-model="remindTo"
                        @input="reminderToDatePicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-icon>mdi-wechat</v-icon>
              <span class="headline">{{
                $vuetify.lang.t('$vuetify.note.many')
              }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-data-table
                :headers="compHeaders"
                :items="notes"
                fixed-header
                item-key="id"
                :loading="notesLoading"
                disable-sort
                disable-pagination
                hide-default-footer
              >
                <template v-slot:item.user="{ item }">
                  <v-avatar class="ma-1" size="30">
                    <img
                      alt="Avatar"
                      :src="item.avatar ? item.avatar : 'static/img/av.png'"
                    />
                  </v-avatar>
                  {{ item.user }}
                </template>
                <template v-slot:item.note="{ item }">
                  <div v-if="item.note">
                    <v-textarea
                      class="disabled-textarea"
                      :value="item.note"
                      readonly
                      rows="2"
                    ></v-textarea>
                  </div>
                  <div v-if="item.attachments">
                    <v-chip
                      class="mr-1 mb-1"
                      v-for="file in item.attachments"
                      :key="file.id"
                      color="primary"
                      outlined
                      @click="download(file)"
                    >
                      <v-icon left>mdi-file</v-icon>
                      {{ file.name }}
                    </v-chip>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-icon>mdi-message</v-icon>
              <span class="headline">{{
                $vuetify.lang.t('$vuetify.note.add')
              }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-form ref="noteForm" lazy-validation v-model="validNote">
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="note"
                      outlined
                      :label="$vuetify.lang.t('$vuetify.note.one') + '*'"
                      :rules="notEmpty"
                      required
                      counter
                      clearable
                      rows="3"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      counter
                      show-size
                      small-chips
                      multiple
                      :label="$vuetify.lang.t('$vuetify.different.files')"
                      v-model="noteFiles"
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn rounded small @click="resetNote" :disabled="noteLoading">
                <v-icon left small>mdi-comment-remove-outline</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.clear') }}
              </v-btn>
              <v-btn
                rounded
                small
                color="success"
                @click="addNote"
                :disabled="!validNote"
                :loading="noteLoading"
              >
                <v-icon left small>mdi-plus</v-icon>
                {{ $vuetify.lang.t('$vuetify.note.add') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog persistent v-model="editDialog" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $vuetify.lang.t('$vuetify.ticket.edit')
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="ticketForm" lazy-validation v-model="validTicketEdit">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="ticket.project_id"
                    :items="compProjects"
                    :label="$vuetify.lang.t('$vuetify.different.project') + '*'"
                    :rules="notEmpty"
                    required
                    item-value="id"
                    item-text="name"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="ticket.category_id"
                    :items="compTicketCategories"
                    :label="$vuetify.lang.t('$vuetify.category.category') + '*'"
                    :rules="notEmpty"
                    required
                    item-value="id"
                    item-text="title"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="ticket.assigned_ids"
                    :items="compUsersList"
                    :label="$vuetify.lang.t('$vuetify.user.assignTo') + '*'"
                    clearable
                    multiple
                    persistent-hint
                    chips
                    item-value="id"
                    item-text="title"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="ticket.order_number"
                    type="number"
                    step="1"
                    clearable
                    :label="$vuetify.lang.t('$vuetify.order.number')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    v-model="reminderDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="250px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="ticket.reminder_date"
                        :label="$vuetify.lang.t('$vuetify.different.remind')"
                        prepend-icon="event"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="nowDate"
                      first-day-of-week="1"
                      v-model="ticket.reminder_date"
                      @input="reminderDatePicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ticket.summary"
                    :label="$vuetify.lang.t('$vuetify.different.summary') + '*'"
                    :hint="$vuetify.lang.t('$vuetify.ticket.summary')"
                    :rules="max255"
                    required
                    counter
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="ticket.description"
                    outlined
                    :label="
                      $vuetify.lang.t('$vuetify.different.description') + '*'
                    "
                    :rules="notEmpty"
                    required
                    counter
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*{{ $vuetify.lang.t('$vuetify.different.indicate') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="editDialog = false"
            :disabled="ticketLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.close') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="edit"
            :disabled="!validTicketEdit"
            :loading="ticketLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="historyDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="historyDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >{{ $vuetify.lang.t('$vuetify.ticket.history') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-data-table
          :headers="compHistoryHeaders"
          :items="historyItems"
          fixed-header
          class="elevation-1"
          item-key="id"
          :items-per-page="10"
          :loading="historyLoading"
          :options.sync="historyOptions"
          :server-items-length="totalHistoryItems"
        >
        </v-data-table>
      </v-card>
    </v-dialog>
    <AppLoader :dialog.sync="loading"></AppLoader>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader'

export default {
  components: {
    AppLoader
  },
  props: {
    id: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      editDialog: false,
      historyDialog: false,
      validTicketEdit: true,
      validNote: true,
      ticketLoading: false,
      notesLoading: false,
      noteLoading: false,
      reminderDatePicker: false,
      reminderToDatePicker: false,
      notEmpty: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required')
      ],
      max255: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        (v) =>
          (v && v.length <= 255) ||
          this.$vuetify.lang.t('$vuetify.validation.max255')
      ],
      ticket: {
        id: null,
        view_id: null,
        category_id: null,
        reporter_id: null,
        project_id: null,
        project: {
          name: null
        },
        assigned: null,
        assigned_ids: null,
        status_id: null,
        order_number: null,
        reminder_date: null,
        reminder_alert: null,
        summary: null,
        description: null,
        created_at: null
      },
      assignTo: null,
      statusTo: null,
      remindTo: null,
      noteFiles: null,
      note: null,
      notes: [],
      order: null,
      historyLoading: false,
      totalHistoryItems: 0,
      historyItems: [],
      historyOptions: {
        page: 1
      },
      nowDate: new Date().toISOString().slice(0, 10)
    }
  },
  metaInfo() {
    return {
      title:
        this.$vuetify.lang.t('$vuetify.ticket.one') +
        ' ' +
        (this.ticket.view_id ? this.ticket.view_id : '')
    }
  },
  watch: {
    editDialog: function(val) {
      if (!val) {
        this.$refs.ticketForm.resetValidation()
      }
    },
    assignTo: function(val) {
      if (val) {
        this.assign()
      }
    },
    statusTo: function(val) {
      if (val) this.status()
    },
    remindTo: function(val) {
      if (val) this.remind()
    },
    'historyOptions.page': function() {
      this.getHistory()
    },
    historyDialog: function(val) {
      if (val) this.getHistory()
      else this.historyOptions.page = 1
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    compTicketCategories() {
      let arr = this.$store.getters.getTicketCategories
      let modified = []
      let t = this
      if (arr) {
        arr.forEach(function(entry) {
          modified.push({
            id: entry.id,
            title: t.$vuetify.lang.t('$vuetify.category.' + entry.id)
          })
        })
      }
      return modified
    },
    compTicketStatuses() {
      let arr = this.$store.getters.getTicketStatuses
      let modified = []
      let t = this
      if (arr) {
        arr.forEach(function(entry) {
          modified.push({
            id: entry.id,
            title: t.$vuetify.lang.t('$vuetify.status.' + entry.id)
          })
        })
      }
      return modified
    },
    compUsersList() {
      return this.$store.getters.getUsersList
    },
    compAuthUser() {
      return this.$store.getters.getAuthUser
    },
    compShowEditBtn() {
      return this.compAuthUser.id * 1 === this.ticket.reporter_id * 1
    },
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    },
    compCategoryTitle() {
      for (const key in this.compTicketCategories) {
        if (this.compTicketCategories[key].id === this.ticket.category_id)
          return this.compTicketCategories[key].title
      }
      return ''
    },
    compReporterTitle() {
      for (const key in this.compUsersList) {
        if (this.compUsersList[key].id === this.ticket.reporter_id)
          return this.compUsersList[key].title
      }
      return ''
    },
    compAssignedTitle() {
      for (const key in this.compUsersList) {
        if (this.compUsersList[key].id === this.ticket.assigned_id)
          return this.compUsersList[key].title
      }
      return ''
    },
    compStatusTitle() {
      for (const key in this.compTicketStatuses) {
        if (this.compTicketStatuses[key].id === this.ticket.status_id)
          return this.compTicketStatuses[key].title
      }
      return ''
    },
    compPositionsHeaders() {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.order.itemNumber'),
          value: 'articleNumber',
          width: '15%'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.itemName'),
          value: 'articleName',
          width: '55%'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.quantity'),
          value: 'quantity',
          width: '10%'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.price'),
          value: 'price',
          width: '10%'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.totalPrice'),
          value: 'total',
          sortable: false,
          width: '10%'
        }
      ]
    },
    compHeaders() {
      return [
        { text: 'ID', value: 'view_id', width: '10%' },
        {
          text: this.$vuetify.lang.t('$vuetify.user.user'),
          value: 'user',
          width: '20%'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.note.one'),
          value: 'note',
          width: '50%'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.createdAt'),
          value: 'created_at',
          width: '20%'
        }
      ]
    },
    compHistoryHeaders() {
      return [
        { text: 'ID', value: 'view_id', width: '10%', sortable: false },
        {
          text: this.$vuetify.lang.t('$vuetify.user.user'),
          value: 'user',
          width: '15%',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.field'),
          value: 'field',
          width: '15%',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.change'),
          value: 'change',
          width: '40%',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.createdAt'),
          value: 'created_at',
          width: '20%'
        }
      ]
    },
    compProjects() {
      return this.$store.getters.getProjects
    }
  },
  methods: {
    init() {
      this.loading = true
      let data = {
        id: this.id
      }
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/ticket/get',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.ticket = response.data.item
            this.notes = response.data.notes
            this.order = response.data.order
          }
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    edit() {
      if (this.$refs.ticketForm.validate()) {
        this.ticketLoading = true
        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/ticket/update',
            this.ticket,
            this.compAuthHeader
          )
          .then((response) => {
            this.ticketLoading = false
            if (response.data.success) {
              this.editDialog = false
              this.init()
            }
          })
          .catch((error) => {
            console.log(error)
            this.ticketLoading = false
          })
      }
    },
    assign() {
      if (this.assignTo) {
        this.loading = true
        let data = {
          id: this.id,
          assigned: this.assignTo
        }
        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/ticket/assign',
            data,
            this.compAuthHeader
          )
          .then((response) => {
            if (response.data.success) {
              this.editDialog = false
              this.init()
            }
            else this.loading = false
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
          })
      }
    },
    status() {
      if (this.statusTo) {
        this.loading = true
        let data = {
          id: this.id,
          status_id: this.statusTo
        }
        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/ticket/status',
            data,
            this.compAuthHeader
          )
          .then((response) => {
            if (response.data.success) {
              this.editDialog = false
              this.ticket.status_id = this.statusTo
              this.statusTo = null
            }
            this.loading = false
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
          })
      }
    },
    remind() {
      this.loading = true
      let data = {
        id: this.id,
        reminder_date: this.remindTo
      }
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/ticket/remind',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.data.success) {
            this.init()
            this.remindTo = null
          } else this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    addNote() {
      if (this.$refs.noteForm.validate()) {
        this.loading = true

        let formData = new FormData()
        //add files
        if (this.noteFiles) {
          for (let i = 0; i < this.noteFiles.length; i++) {
            let file = this.noteFiles[i]
            formData.append('files[' + i + ']', file)
          }
        }

        // add note
        formData.append('note', this.note)
        formData.append('ticket_id', this.id)

        let headers = this.compAuthHeader
        headers.headers['Content-Type'] = 'multipart/form-data'

        this.$axios
          .post(this.$store.getters.getApiUrl + 'api/note', formData, headers)
          .then((response) => {
            if (response.data.success) {
              this.getNotes()
              this.resetNote()
              this.editDialog = false
            }
            this.loading = false
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
          })
      }
    },
    resetNote() {
      this.note = null
      this.noteFiles = null
      this.$refs.noteForm.resetValidation()
    },
    getNotes() {
      this.notesLoading = true
      let data = {
        ticket_id: this.id
      }
      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'

      this.$axios
        .post(this.$store.getters.getApiUrl + 'api/notes', data, headers)
        .then((response) => {
          if (response.status === 200) {
            this.notes = response.data.notes
          }
          this.notesLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.notesLoading = false
        })
    },
    download(item) {
      let config = this.compAuthHeader
      config.responseType = 'blob'
      this.loading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/note/file',
          { id: item.id },
          config
        )
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          )
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute('download', item.name)
          document.body.appendChild(link)
          link.click()
          link.remove()
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    getHistory() {
      this.historyLoading = true
      let data = {
        ticket_id: this.id,
        page: this.historyOptions.page,
        per_page: this.historyOptions.itemsPerPage
      }

      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/ticket-history',
          data,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            this.historyItems = response.data.data
            this.totalHistoryItems = response.data.meta.total
          }

          this.historyLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.historyLoading = false
        })
    }
  }
}
</script>

<style>
.disabled-textarea textarea {
  color: rgba(0, 0, 0, 1) !important;
}
</style>
